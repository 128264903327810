import { useEffect, useState } from "react";
import { fetchData } from "../api";
import { categorizeData } from "../utils/utils";

function useCategorizedData() {
    const [categorizedData, setCategorizedData] = useState(null);
    const [error, setError] = useState(null);
    const subdomain = process.env.REACT_APP_SUBDOMAIN_ID;

    useEffect(() => {
        async function fetchCategorizedData() {
            try {
                const response = await fetchData(
                    `/api/subdomains/${subdomain}/?populate[products][populate]=*`
                );
                const productsData = response.data;

                if (productsData) {
                    setCategorizedData(categorizeData(productsData));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error);
            }
        }

        fetchCategorizedData();
    }, [subdomain]); // Added subdomain as a dependency

    return { categorizedData, error }; // Return the data and error
}

export default useCategorizedData;