import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
import { postData } from "../api";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

function RightBox() {
  const [formFile, setFormFile] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    message: "",
    enq_type: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [reCaptcha, setReCaptcha] = useState(null);
  const [reCaptchaError, setReCaptchaError] = useState(false);
  const handleCaptchaChange = (value) => {
    setReCaptcha(value);
    setReCaptchaError(false);
  };

  async function handleFormSubmit(event) {
    event.preventDefault();
    if (!reCaptcha) {
      setReCaptchaError(true);
      return;
    }

    let fd = new FormData();
    Object.keys(formData).forEach((key) => {
      fd.append(key, formData[key]);
    });
    if (formFile) {
      fd.append("file", formFile);
    }
    if (reCaptcha) {
      fd.append("recaptcha", reCaptcha);
    }

    const res = await postData("/api/inquiries", fd);
    if (res.success) {
      navigate("/thank-you");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "", // Clear phone field
        country: "",
        message: "",
        enq_type: "",
      });
      setFormFile(null); // Clear file field
      setReCaptcha(null);
      setReCaptchaError(false);
    }
  }
  const handlePhoneChange = (phone) => {
    setFormData({
      ...formData,
      phone: phone,
    });
  };
  return (
    <div className="content_box__right">
      <div className="content_box__right_form">
        <h3 className="heading_footer py-4">
          Get in touch today with our experts
        </h3>
        <p className="footer_para py-2">
          Let us know what you're interested in or looking for, and a
          representative will contact you at the earliest.
        </p>
        <form className="py-4" onSubmit={handleFormSubmit} id="footer_form">
          <div className="row form-group">
            <div className="row col-12 mb-3">
              <label htmlFor="enq_type">
                Enquiry type <b>*</b>
              </label>
              <select
                name="enq_type"
                id="enq_type"
                onChange={handleInputChange}
                required
                value={formData.enq_type}
              >
                <option disabled Value="">
                  -Please choose an option-
                </option>

                <option value="reservation & booking">
                  Reservation & Booking
                </option>
                <option value="Careers">Careers</option>
                <option value="general">General</option>
              </select>
            </div>
            <div className="mb-3 col-12 col-sm-6">
              <label htmlFor="firstName" className="form-label">
                First Name
                <b>*</b>
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="form-control"
                placeholder="Your First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3 col-12 col-sm-6">
              <label htmlFor="lastName" className="form-label">
                Last Name
                <b>*</b>
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="form-control"
                placeholder="Your Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="row form-group my-4">
            <div className="mb-3 col-12 col-sm-6">
              <label htmlFor="email" className="form-label">
                Email
                <b>*</b>
              </label>
              <input
                type="text"
                name="email"
                id="email"
                className="form-control"
                placeholder="Your email address"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3 col-12 col-sm-6">
              <label htmlFor="phone" className="form-label">
                Phone
                <b>*</b>
              </label>
              <PhoneInput
                country={"us"}
                enableSearch={true}
                disableSearchIcon={true}
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                value={formData.phone} // Set value directly from formData
                onChange={handlePhoneChange} // Use handlePhoneChange function
              />
            </div>
          </div>
          <div className="row form-group my-4">
            <div className="mb-3 col-12 col-sm-6 form-group">
              <label htmlFor="country" className="form-label">
                Country
                <b>*</b>
              </label>
              <ReactFlagsSelect
                searchable={true}
                selected={formData.country}
                onSelect={(code) =>
                  setFormData({
                    ...formData,
                    country: code,
                  })
                }
              />
            </div>
            <div className="mb-3 col-12 col-sm-6">
              <label htmlFor="file" className="form-label">
                File
              </label>
              <input
                type="file"
                name="file"
                id="file"
                className="form-control"
                placeholder="your attachment"
                aria-describedby="helpId"
                onChange={(e) => {
                  setFormFile(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="row form-group my-4">
            <label htmlFor="descritpiton" className="form-label">
              Leave us a few words
            </label>
            <textarea
              name="message"
              id="message"
              cols={20}
              rows={10}
              placeholder="Enter your message here"
              value={formData.message}
              onChange={handleInputChange}
            />
          </div>
          <div className="row form-group my-4">
            <div className="mb-3 col-12 col-sm-6 form-group text-center">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={handleCaptchaChange}
              />
              {reCaptchaError && (
                <p className="text-danger">
                  To continue, please verify the CAPTCHA.
                </p>
              )}
            </div>
            <div className="mb-3 col-12 col-sm-6 form-group d-flex justify-content-center align-items-center">
              <input
                type="submit"
                className="button_submit"
                defaultValue="Submit"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RightBox;
