import { useState, useEffect } from "react";
import { postData } from "../api";
import { useNavigate } from 'react-router-dom';


const useLeadForm = (data, numberOptions) => {
    const navigate = useNavigate();
    const [visitors, setVisitors] = useState({
        adults: numberOptions.length > 0 ? 1 : 0,
        children: 0,
    });
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subdomain, setSubdoamin] = useState({
        title: data.subdomain.data.attributes.title,
        slug: data.subdomain.data.attributes.slug,
    });

    const [selectedDate, setSelectedDate] = useState(null);
    const [errors, setErrors] = useState({});
    const [numberOfNights, setNumberOfNights] = useState(1);
    const [name, setName] = useState("");
    const [nationality, setNationality] = useState("")
    const [budget, setBudget] = useState("");
    const [note, setNote] = useState("");



    const validateForm = () => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!visitors.adults) newErrors.adults = "Required adults.";
        if (!selectedDate) newErrors.date = "Please select a date.";
        if (!name) newErrors.name = "Please enter name";
        if (!nationality) newErrors.nationality = "Please enter nationality";
        if (!budget) newErrors.budget = "Please enter budget";
        if (!emailRegex.test(email)) newErrors.email = "Please enter valid email";
        if (!phone) newErrors.phone = "Please enter phone number";



        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            const bookingData = {
                slug: data.slug,
                adults: visitors.adults,
                email: email,
                phone: phone,
                children: visitors.children,
                date: selectedDate,
                numberOfNights,
                budget,
                nationality,
                name,
                product: data.title,
                note,
                subdomain,





            }


            try {
                const res = await postData("/api/booking-leads", bookingData);

                if (res.status === 'success') {
                    // Redirect to thank you page
                    navigate('/thank-you');
                } else {
                    // Handle errors if necessary
                    console.error('Error submitting booking data:', res);
                }
            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

    }
    return {
        visitors,
        setVisitors,
        selectedDate,
        setSelectedDate,
        email,
        setEmail,
        phone,
        setPhone,

        errors,
        handleSubmit,
        numberOfNights,
        setNumberOfNights,
        name,
        setName,
        nationality,
        setNationality,
        budget,
        setBudget,
        note,
        setNote

    };
};





export default useLeadForm;