import React from "react";

import BookingForm from "./BookingForm";
import LeadForm from "./LeadForm";

function BookingCard({ data }) {
  const paymentGatewayEnabled = process.env.REACT_APP_PAYMENTGATEWAY === "true";

  return (
    <div className="booking_card shadow">
      <h2 className="text-center p-3 fs-4 ">Reserve Your Spot Now!</h2>
      {paymentGatewayEnabled ? (
        <BookingForm data={data} />
      ) : (
        <LeadForm data={data} />
      )}
    </div>
  );
}

export default BookingCard;
