import React from "react";
import { Select, DatePicker, Input } from "antd";
import useLeadForm from "../hooks/useLeadForm";
// import PhoneInput from "react-phone-input-2";
import PhoneInput from "antd-phone-input";
import { generateNumbers, disabledDate } from "../helpers/helper";

function LeadForm({ data }) {
  const numberOptions = generateNumbers(50);
  const numOfNights = numberOptions.filter((num) => {
    return num.value > 0;
  });

  const { TextArea } = Input;
  const {
    visitors,
    setVisitors,
    selectedDate,
    setSelectedDate,
    numberOfNights,
    setNumberOfNights,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    nationality,
    setNationality,
    budget,
    setBudget,
    note,
    setNote,
    errors,
    handleSubmit,
  } = useLeadForm(data, numberOptions);

  return (
    <form className="p-2 p-md-4 " onSubmit={handleSubmit}>
      <div className="pb-4 form_fields_wrapper">
        <div className="py-2">
          <label>Name</label>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Your name"
            status={errors.name ? "error" : ""}
          />
          {errors.name && <div style={{ color: "red" }}>{errors.name}</div>}
        </div>
        <div className="py-2">
          <label>Email</label>
          <Input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Your Email"
            status={errors.email ? "error" : ""}
          />
          {errors.email && <div style={{ color: "red" }}>{errors.email}</div>}
        </div>
        <div className="py-2">
          <label>Phone</label>
          <PhoneInput
            country={"us"}
            enableSearch={true}
            enableArrow={true}
            value={phone} // Set value directly from formData
            status={errors.phone ? "error" : ""}
            disableParentheses={true}
            onChange={(value, event) => {
              setPhone(value);
            }} // Use handlePhoneChange function
          />
        </div>

        <div className="row">
          <div className="col-6 py-2">
            <label>Check-in date</label>
            <DatePicker
              className="w-100"
              disabledDate={disabledDate}
              onChange={(date) => setSelectedDate(date)}
              status={errors.date ? "error" : ""}
              value={selectedDate}
            />
            {errors.date && <div style={{ color: "red" }}>{errors.date}</div>}
          </div>
          <div className="col-6 py-2">
            <label>No of Nights</label>
            <Select
              options={numOfNights}
              style={{ display: "block" }}
              value={numberOfNights}
              onChange={(value) => setNumberOfNights(value)}
            />
          </div>
        </div>
        <div className="py-2">
          <label>Nationality</label>
          <Input
            value={nationality}
            onChange={(e) => {
              setNationality(e.target.value);
            }}
            placeholder="Your Nationality"
            status={errors.nationality ? "error" : ""}
          />
          {errors.nationality && (
            <div style={{ color: "red" }}>{errors.nationality}</div>
          )}
        </div>

        <div className="py-2">
          <label>Preferred budget (add your currency)</label>
          <Input
            value={budget}
            onChange={(e) => {
              setBudget(e.target.value);
            }}
            placeholder="Your budget"
            status={errors.budget ? "error" : ""}
          />
          {errors.budget && <div style={{ color: "red" }}>{errors.budget}</div>}
        </div>

        <div className="row py-2 ">
          {numberOptions.length > 0 && (
            <>
              <div className="col-6">
                <label>No of Adults</label>

                <Select
                  options={numberOptions}
                  style={{ display: "block" }}
                  value={visitors.adults}
                  status={errors.adults ? "error" : ""}
                  onChange={(value) =>
                    setVisitors((prevState) => ({
                      ...prevState,
                      adults: value,
                    }))
                  }
                />
                {errors.adults && (
                  <div style={{ color: "red" }}>{errors.adults}</div>
                )}
              </div>
              <div className="col-6">
                <label>No of Children</label>

                <Select
                  options={numberOptions}
                  style={{ display: "block" }}
                  value={visitors.children}
                  onChange={(value) =>
                    setVisitors((prevState) => ({
                      ...prevState,
                      children: value,
                    }))
                  }
                />
              </div>
            </>
          )}
        </div>

        <div className="py-2">
          <label>Special notes</label>
          <TextArea
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            placeholder="Your special note"
            showCount
            maxLength={500}
            rows={2}
          />
        </div>
      </div>

      <div>
        <button type="submit" className="button_book_now">
          Get a quote
        </button>
      </div>
    </form>
  );
}

export default LeadForm;
