import { Link } from "react-router-dom";
import useModal from "../hooks/useModel";
import BookingModel from "./BookingModel";
import { commaSepratedNumbers } from "../utils/utils";

export default function ProductCard({ product }) {
  const [isModalOpen, showModal, handleCancel] = useModal();
  const image = product.banner_image?.data?.attributes?.url;

  return (
    <>
      <BookingModel
        data={product}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
      />
      <div className="product_card">
        <div
          className="product_card__header"
          style={
            image && {
              backgroundImage: `url(${process.env.REACT_APP_API_URL + image})`,
            }
          }
        >
          <span className="product-card__header-label">Special Offer</span>
        </div>
        <div className="product_card__content p-2">
          <div className="product_card__content_title_detail">
            <img src="/img/icons/location_icon.svg" alt="Location Icon" />
            <h6 className="product_card__content_title">{product.title}</h6>
          </div>
          <hr className="product_card__devider" />
          <div className="product_card__content_price_details">
            <img src="/img/icons/discount_icon.svg" alt="" />
            <div className="product_card__content_price_details_starting">
              <div className="product_card__price_remark">Starting from</div>
              <div className="product_card__price">
                {product.currency ?? ""}{" "}
                {product.starting_price
                  ? new Intl.NumberFormat().format(product.starting_price)
                  : ""}
              </div>
            </div>
          </div>
          <div className="product_card__cta py-3">
            <Link
              to={`/${product.product_type.data.attributes.slug}/${product.slug}`}
              className="btn btn-outline-dark"
            >
              More Info
            </Link>
            <button className="btn btn-dark mx-2" onClick={showModal}>
              Book Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
