import React from "react";
import { Modal } from "antd";

import BookingForm from "./BookingForm";
import LeadForm from "./LeadForm";

function BookingModel({ data, isModalOpen, handleCancel }) {
  const paymentGatewayEnabled = process.env.REACT_APP_PAYMENTGATEWAY === "true";
  return (
    <>
      <Modal
        title={data.title}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {paymentGatewayEnabled ? (
          <BookingForm data={data} />
        ) : (
          <LeadForm data={data} />
        )}
      </Modal>
    </>
  );
}

export default BookingModel;
