import React from "react";
import { Select, DatePicker, Radio } from "antd";
import useBookingForm from "../hooks/useBookingForm";
import { generateNumbers, disabledDate } from "../helpers/helper";
import { commaSepratedNumbers } from "../utils/utils";

function BookingForm({ data }) {
  const numberOptions = generateNumbers(50);

  const {
    visitors,
    setVisitors,
    selectedDate,
    setSelectedDate,
    setSelectedTime,
    errors,
    handleSubmit,
    ticketTypesAvailable,
    transportTypesAvailable,
    timeAvailable,
    timeLabel,
    mappedTicketTypes,
    mappedTransportTypes,
    selectedTransport,
    setSelectedTransport,
    selectedTicketType,
    setSelectedTicketType,
    adultPrice,
    childPrice,
    total,
  } = useBookingForm(data, numberOptions);

  return (
    <form className="p-2 p-md-4" onSubmit={handleSubmit}>
      <div className="row">
        {numberOptions.length > 0 && (
          <>
            <div className="col-6">
              <label>No of Adults</label>
              <p className="text-white d-block invisible">
                placeholder placeholder
              </p>
              <Select
                options={numberOptions}
                style={{ display: "block" }}
                value={visitors.adults}
                onChange={(value) =>
                  setVisitors((prevState) => ({
                    ...prevState,
                    adults: value,
                  }))
                }
              />
              {errors.adults && (
                <div style={{ color: "red" }}>{errors.adults}</div>
              )}
            </div>
            <div className="col-6">
              <label>No of Children</label>
              <p className="text-danger d-block">Please read age policy</p>
              <Select
                options={numberOptions}
                style={{ display: "block" }}
                value={visitors.children}
                onChange={(value) =>
                  setVisitors((prevState) => ({
                    ...prevState,
                    children: value,
                  }))
                }
              />
            </div>
          </>
        )}
      </div>
      {transportTypesAvailable && (
        <div className="py-2">
          <label>Transport Type</label>
          <Select
            style={{ display: "block" }}
            options={mappedTransportTypes}
            value={selectedTransport.value}
            onChange={(value, option) => {
              if (option) {
                setSelectedTransport({
                  id: option.id,
                  label: option.label,
                  value: option.value,
                  adultPrice: option.adultPrice,
                  childPrice: option.childPrice,
                });
              }
            }}
          />
        </div>
      )}

      {ticketTypesAvailable && (
        <div className="py-2">
          <label>Ticket Type</label>
          <Select
            placeholder="Select Ticket Type"
            style={{ display: "block" }}
            options={mappedTicketTypes}
            value={selectedTicketType.value}
            onChange={(value, option) => {
              if (option) {
                setSelectedTicketType({
                  id: option.id,
                  value: option.value,
                  label: option.label,
                  adultPrice: option.adultPrice,
                  childPrice: option.childPrice,
                });
              }
            }}
          />
        </div>
      )}
      <div className="py-2">
        <label>Select Date</label>
        <DatePicker
          className="w-100"
          disabledDate={disabledDate}
          onChange={(date) => setSelectedDate(date)}
          value={selectedDate}
        />
        {errors.date && <div style={{ color: "red" }}>{errors.date}</div>}
      </div>

      {timeAvailable && (
        <div className="py-2">
          <label>Select Time</label>
          <Radio.Group
            buttonStyle="solid"
            required
            onChange={(time) => setSelectedTime(time.target.value)}
          >
            {timeLabel.map((time) => {
              return (
                <Radio.Button value={time.label} className="m-1 radio-override">
                  {time.label}
                </Radio.Button>
              );
            })}
          </Radio.Group>
          {errors.time && <div style={{ color: "red" }}>{errors.time}</div>}
        </div>
      )}
      <div className="row">
        <div className="col-6">
          Adult:{" "}
          <b className="fw-bold">
            {data.currency} {commaSepratedNumbers(visitors.adults * adultPrice)}
          </b>
        </div>
        <div className="col-6 text-end">
          Child:{" "}
          <b className="fw-bold">
            {data.currency}{" "}
            {commaSepratedNumbers(visitors.children * childPrice)}
          </b>
        </div>
      </div>
      <div className="text-end fs-5 py-4">
        Total:{" "}
        <b className="fs-3">
          {data.currency} {commaSepratedNumbers(total)}
        </b>
      </div>
      <div>
        <button type="submit" className="button_book_now">
          Book now
        </button>
      </div>
    </form>
  );
}

export default BookingForm;
